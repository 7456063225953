import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import checkTrue from '../assets/images/checkTrue.png';
import checkFalse from '../assets/images/checkFalse.png';

interface IProps {
    tokenFeatures?: any;
};

export default class TokenDetailSlick extends React.Component<IProps> {
    slider: any;

    constructor(props: IProps) {
        super(props);
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };

        return (
            <>
                <div>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        {this.props.tokenFeatures.map((item: any) => {
                            return (
                                <div className='flex flex-col items-center text-center'>
                                    <p className='text-white'>{item.name}</p>
                                    {
                                        item.type === "true" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkTrue} width={20} height={20} alt="true" />
                                            </div>
                                        ) : item.type === "false" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkFalse} width={20} height={20} alt="false" />
                                            </div>
                                        ) : (
                                            <p>{item.type}</p>
                                        )
                                    }
                                    {
                                        item.governance === "true" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkTrue} width={20} height={20} alt="true" />
                                            </div>
                                        ) : item.governance === "false" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkFalse} width={20} height={20} alt="false" />
                                            </div>
                                        ) : (
                                            <p>{item.governance}</p>
                                        )
                                    }
                                    {
                                        item.community === "true" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkTrue} width={20} height={20} alt="true" />
                                            </div>
                                        ) : item.community === "false" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkFalse} width={20} height={20} alt="false" />
                                            </div>
                                        ) : (
                                            <p>{item.community}</p>
                                        )
                                    }
                                    {
                                        item.apr === "true" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkTrue} width={20} height={20} alt="true" />
                                            </div>
                                        ) : item.apr === "false" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkFalse} width={20} height={20} alt="false" />
                                            </div>
                                        ) : (
                                            <p>{item.apr}</p>
                                        )
                                    }
                                    {
                                        item.airdrop === "true" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkTrue} width={20} height={20} alt="true" />
                                            </div>
                                        ) : item.airdrop === "false" ? (
                                            <div className='flex justify-center'>
                                                <img src={checkFalse} width={20} height={20} alt="false" />
                                            </div>
                                        ) : (
                                            <p>{item.airdrop}</p>
                                        )
                                    }
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </>
        );
    }
}
