export function getStringFromNumber(num: number){
    if (num < 1.0) return num.toString();

    let strNum = num.toFixed(0).toString();
    let fixedNum = '';
    let c = 0;
    for (let i = strNum.length - 1; i >= 0; i--) {
        c++;
        fixedNum += strNum[i];
        if (c === 3 && i !== 0) {
            fixedNum += ",";
            c = 0;
        }
    }

    return fixedNum.split("").reverse().join("");
}