import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imgShiba from '../assets/images/shiba.png';
import imgChihuahua from '../assets/images/chihuahua.png';
import imgDoge from '../assets/images/doge.png';
import { getStringFromNumber } from "../utills";

interface IProps {
    tokenDetails?: any;
};

export default class TokenDetailSlick extends React.Component<IProps> {
    slider: any;

    constructor(props: IProps) {
        super(props);
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };

        return (
            <>
                <div>
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        {this.props.tokenDetails.map((item: any, index: any) => {
                            return (
                                <div key={index} className='flex flex-col items-center text-center'>
                                    <div className='flex flex-col items-center pb-4'>
                                        {
                                            item.name === "shiba-inu" && (
                                                <img src={imgShiba} width={32} height={32} alt="shiba"></img>
                                            )
                                        }
                                        {
                                            item.name === "dogecoin" && (
                                                <img src={imgDoge} width={32} height={32} alt="doge"></img>
                                            )
                                        }
                                        {
                                            item.name === "chihuahua" && (
                                                <img src={imgChihuahua} width={32} height={32} alt="chihuahua"></img>
                                            )
                                        }
                                        <p className='text-lg'>{item.price.toFixed(7).toString()}</p>
                                    </div>
                                    {
                                        item.one_hour >= 0 ? (
                                            <p className='text-green-500'>{"+" + item.one_hour.toFixed(2).toString() + "%"}</p>
                                        ) : (
                                            <p className='text-red-500'>{item.one_hour.toFixed(2).toString() + "%"}</p>
                                        )
                                    }
                                    {
                                        item.one_day >= 0 ? (
                                            <p className='text-green-500'>{"+" + item.one_day.toFixed(2).toString() + "%"}</p>
                                        ) : (
                                            <p className='text-red-500'>{item.one_day.toFixed(2).toString() + "%"}</p>
                                        )
                                    }

                                    {
                                        item.one_week >= 0 ? (
                                            <p className='text-green-500'>{"+" + item.one_week.toFixed(2).toString() + "%"}</p>
                                        ) : (
                                            <p className='text-red-500'>{item.one_week.toFixed(2).toString() + "%"}</p>
                                        )
                                    }
                                    <p>$ {getStringFromNumber(item.volume)}</p>
                                    <p>$ {getStringFromNumber(item.marketcap)}</p>
                                    <p>$ {item.all_time_hight.toFixed(7).toString()}</p>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </>
        );
    }
}
