import React, { useEffect, useState, useRef } from 'react';
import useAxios from "axios-hooks";
import logo from './assets/images/logo.png';
import imgHero from './assets/images/img_hero.png';
import imgDog from './assets/images/dog.png';
import imgMobileDog from './assets/images/mobile-dog.png'
import imgDivideCurve from './assets/images/divideCurve.png';
import imgSub from './assets/images/subImage.png';
import imgSub1 from './assets/images/subImage1.png'
import imgSub2 from './assets/images/subImage2.png'
import imgShiba from './assets/images/shiba.png';
import imgChihuahua from './assets/images/chihuahua.png';
import imgDoge from './assets/images/doge.png';
import chartSubImage from './assets/images/chartSubImage.png';
import chartSubDotImage from './assets/images/chartSubDotImage.png';
import chartRightImage from './assets/images/chartRightImage.png';
import burgeDogImage from './assets/images/burgeDogImage.png';
import featureImage from './assets/images/featureImage.png';
import checkTrue from './assets/images/checkTrue.png'
import checkFalse from './assets/images/checkFalse.png'
import infoDisable from "./assets/images/info_disable.png"
import infoEnable from "./assets/images/info_enable.png"
import footerImage from "./assets/images/footer_image.png"

import './assets/styles/App.scss';
import { DataProps } from "interfaces/DataProps";

import TokenDetailSlick from "./components/TokenDetailSlick";
import TokenFeatureSlick from "./components/TokenFeatureSlick";

import { AdvancedChart } from "react-tradingview-embed";
import { getStringFromNumber } from "./utills";
import { MarketContext } from "store/MarketProvider";
import useWindowDimensions from "hooks/useWindowDimensions";
import PrimaryChart from "components/PrimaryChart";

const tokenFeatures = [
  {
    name: "Chihuahua",
    apr: "~400%",
    governance: "true",
    community: " ~10B HUAHUA",
    type: "BFT PoS",
    airdrop: "true"
  },
  {
    name: "Dogecoin",
    apr: "false",
    governance: "false",
    community: "false",
    type: "PoW",
    airdrop: "false"
  },
  {
    name: "Shiba Inu",
    apr: "false",
    governance: "false",
    community: "false",
    type: "Token",
    airdrop: "false"
  }
]

interface ITokenData {
  name: string,
  price: number,
  one_hour: number,
  one_day: number,
  one_week: number,
  volume: number,
  marketcap: number,
  all_time_hight: number
}

function App() {
  const [tokenType, setTokenType] = useState("CHIHUAHUA");
  const [shibaToken, setShibaToken] = useState<ITokenData>({ name: '', price: 0.0, one_hour: 0.0, one_day: 0.0, one_week: 0.0, volume: 0.0, marketcap: 0.0, all_time_hight: 0.0 });
  const [dogeToken, setDogeToken] = useState<ITokenData>({ name: '', price: 0.0, one_hour: 0.0, one_day: 0.0, one_week: 0.0, volume: 0.0, marketcap: 0.0, all_time_hight: 0.0 });
  const [chihuahuaToken, setChihuahuaToken] = useState<ITokenData>({ name: '', price: 0.0, one_hour: 0.0, one_day: 0.0, one_week: 0.0, volume: 0.0, marketcap: 0.0, all_time_hight: 0.0 });
  const [info, setInfo] = useState("");
  const [marketcap, setMarketCap] = useState(0.0);
  const [priceChange, setPriceChange] = useState(0.0);
  const [mappedData, setMappedData] = useState<DataProps[]>();
  const [boxWidth, setBoxWidth] = React.useState<number>(0);
  const { height } = useWindowDimensions();
  const tokenList = ['shiba-inu', 'dogecoin', 'chihuahua-token'];
  const [timeRange, setTimeRange] = useState(1);
  const gridItemRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    for (let i = 0; i < tokenList.length; i++) {
      fetch(`https://api.coingecko.com/api/v3/coins/${tokenList[i]}?tickers=false&community_data=false&developer_data=false`, {
        mode: 'cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        }
      })
        .then((response) => response.json())
        .then((data) => {
          if (tokenList[i] === 'shiba-inu') {
            setShibaToken({
              name: 'shiba-inu',
              price: data.market_data.current_price.usd,
              one_hour: data.market_data.price_change_percentage_1h_in_currency.usd,
              one_day: data.market_data.price_change_percentage_24h_in_currency.usd,
              one_week: data.market_data.price_change_percentage_7d_in_currency.usd,
              volume: data.market_data.total_volume.usd,
              marketcap: data.market_data.market_cap.usd,
              all_time_hight: data.market_data.high_24h.usd
            });
          } else if (tokenList[i] === 'dogecoin') {
            setDogeToken({
              name: 'dogecoin',
              price: data.market_data.current_price.usd,
              one_hour: data.market_data.price_change_percentage_1h_in_currency.usd,
              one_day: data.market_data.price_change_percentage_24h_in_currency.usd,
              one_week: data.market_data.price_change_percentage_7d_in_currency.usd,
              volume: data.market_data.total_volume.usd,
              marketcap: data.market_data.market_cap.usd,
              all_time_hight: data.market_data.high_24h.usd
            });
          } else {
            setChihuahuaToken({
              name: 'chihuahua',
              price: data.market_data.current_price.usd,
              one_hour: data.market_data.price_change_percentage_1h_in_currency.usd,
              one_day: data.market_data.price_change_percentage_24h_in_currency.usd,
              one_week: data.market_data.price_change_percentage_7d_in_currency.usd,
              volume: data.market_data.total_volume.usd,
              marketcap: data.market_data.current_price.usd * 100000000000,
              all_time_hight: data.market_data.high_24h.usd
            });
          }
        })
        .catch((err) => { console.log(err) })
    }
  }, [])

  useEffect(() => {
    console.log('gridItemRef', gridItemRef);

    const handleResize = (width?: number) => {
      setBoxWidth(width || 0);
    };

    handleResize(gridItemRef.current?.clientWidth || 0);

    window.addEventListener("resize", () =>
      handleResize(gridItemRef?.current?.clientWidth || 0)
    );

    return () => {
      window.removeEventListener("resize", () => handleResize());
    };
  }, [gridItemRef]);

  useEffect(() => {
    console.log('height', Math.floor(height * 0.4))
    console.log('width', boxWidth);
  }, [height])

  useEffect(() => {
    let coin = 'DOGE';
    if(tokenType === "DOGE") coin = 'dogecoin';
    else if(tokenType === "SHIBA") coin = 'shiba-inu';
    else coin = 'chihuahua-token';
    fetch(`https://api.coingecko.com/api/v3/coins/${coin}/market_chart?vs_currency=usd&days=${timeRange}`, {
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('data', data);
        let mappingData: DataProps[] = [];
        for (let i = 0; i < data.prices.length; i++) {
          const ele: DataProps = {
            date: new Date(data.prices[i][0]).toString(),
            price: data.prices[i][1]
          }
          mappingData.push(ele);
        }

        setMappedData(mappingData);
      })
      .catch((err) => { console.log(err) })
  }, [tokenType, timeRange])

  useEffect(() => {
    let total_marketcap = (shibaToken.marketcap + chihuahuaToken.marketcap + dogeToken.marketcap) / 1000000000;
    let avg_change = (shibaToken.one_day + chihuahuaToken.one_day + dogeToken.one_day) / 3;

    setMarketCap(total_marketcap);
    setPriceChange(avg_change);
  }, [shibaToken, chihuahuaToken, dogeToken])

  const changeToken2Doge = () => {
    setTokenType("DOGE");
  }

  const changeToken2Chihuahua = () => {
    setTokenType("CHIHUAHUA");
  }

  const changeToken2Shiba = () => {
    setTokenType("SHIBA")
  }

  const handleInfoClick = (data: any) => {
    let value = '';
    if (info !== data) value = data;
    setInfo(value);
  }

  return (
    <div className='App relative' ref={gridItemRef}>
      <header className='px-8'>
        <img src={logo} alt="logo"></img>
      </header>

      <div className='relative py-16' style={boxWidth >= 1024 ? {height: '60vh'} : boxWidth >= 768 ? {height: '40vh'} : {height: '20vh'}}>
        <div className='z-20 absolute w-full'>
          <p className=' text-sm sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-white font-bold' >WELCOME TO MEMECOIN</p>
          <p className='pt-2 sm:pt-4 md:pt-6 lg:pt-10 xl:pt-16 text-3xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl 2xl:text-9xl text-white font-bold italic'>THE WORLD’S #1 <br />OF DATA MEME COIN</p>
        </div>
        <img className='z-10 absolute top-5 xl:top-10 left-0 w-40 sm:w-48 md:w-64 lg:w-80 xl:w-1/4' src={imgHero} alt="hero"></img>
      </div>
      <div className='section-one relative pl-10 xs:pl-20 sm:pl-40 md:pl-48 2xl:pl-72 pr-10 sm:pr-20 md:pr-48 lg:pr-0 pb-0 xl:pb-20'>
        <div className='grid grid-cols-3'>
          <div className='col-span-3 lg:col-span-2 grid grid-cols-2 items-center relative'>
            <div className="balance -mt-0 lg:-mt-36">
              <hr />
              <div className='flex flex-col border-l-2 pl-4'>
                <p className='text-xs xl:text-lg font-semibold py-1 xl:py-3'>today</p>
                <p className='text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-semibold'>${marketcap.toFixed(1).toString()}B</p>
                <p className='text-xs xl:text-lg font-semibold py-1 xl:py-3'>The Meme Tokens market cap</p>
              </div>
            </div>
            <div className="balance mt-36 lg:mt-0">
              <div className='flex flex-col pl-8 xl:pl-20'>
                <p className='text-xs xl:text-lg font-semibold py-1 xl:py-3'>In the last 24h</p>
                <p className='text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-semibold'>{priceChange > 0.0 ? "+" + priceChange.toFixed(1).toString() : priceChange.toFixed(1).toString()}%</p>
                <p className='text-xs xl:text-lg font-semibold py-1 xl:py-3'>Change</p>
              </div>
            </div>
            <img className='hidden lg:block absolute -top-32 right-1/2 w-96 lg:w-72 xl:w-80 ml-10' style={{ transform: 'rotate(15deg)' }} src={imgDivideCurve} alt="curve"></img>
          </div>
          <div className='hidden lg:flex items-center justify-center'>
            <img src={imgDog} alt="dog"></img>
          </div>
        </div>
        <div className='flex items-center justify-center transform -translate-y-1/3 lg:hidden'>
          <img src={imgMobileDog} alt="dog"></img>
        </div>
        <img className='hidden lg:block absolute left-0 bottom-0' src={imgSub} alt="sub"></img>
        <img className='lg:hidden absolute left-8 bottom-1/2' src={imgSub1} alt="sub"></img>
        <img className='lg:hidden absolute right-8 top-0' src={imgSub2} alt="sub"></img>
      </div>
      <div className='section-two px-4 md:px-20 lg:px-28 xl:px-48 2xl:px-72 -mt-80 lg:-mt-0 z-10 justify-center'>
        <p className='-mt-36 text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-semibold italic py-3'>MEME STATS</p>
        <div className='table-back px-4 md:px-10 py-4 md:py-10 flex flex-row items-end place-content-between'>
          <div className='flex flex-col items-start'>
            <p className='text-left'>1h change</p>
            <p className='text-left'>24h change</p>
            <p className='text-left'>7d change</p>
            <p className='text-left'>24h Volume</p>
            <p className='text-left'>Market Cap</p>
            <p className='text-left'>All Time High</p>
          </div>
          {
            [{ ...chihuahuaToken }, { ...dogeToken }, { ...shibaToken }].map((item, index) => (
              <div key={index} className='hidden sm:flex flex-col items-center'>
                <div className='flex flex-col items-center pb-4'>
                  {
                    item.name === "shiba-inu" && (
                      <a href='https://shibatoken.com/' target='_blank'><img src={imgShiba} width={64} height={64} alt="shiba"></img></a>
                    )
                  }
                  {
                    item.name === "dogecoin" && (
                      <a href='https://dogecoin.com/' target='_blank'><img src={imgDoge} width={64} height={64} alt="doge"></img></a>
                    )
                  }
                  {
                    item.name === "chihuahua" && (
                      <a href='https://chihuahua.wtf/' target='_blank'><img src={imgChihuahua} width={64} height={64} alt="chihuahua"></img></a>
                    )
                  }
                  <p className='text-lg pt-2'>${item.price.toFixed(7).toString()}</p>
                </div>
                {
                  item.one_hour >= 0 ? (
                    <p className='text-green-500'>{"+" + item.one_hour.toFixed(2).toString() + "%"}</p>
                  ) : (
                    <p className='text-red-500'>{item.one_hour.toFixed(2).toString() + "%"}</p>
                  )
                }
                {
                  item.one_day >= 0 ? (
                    <p className='text-green-500'>{"+" + item.one_day.toFixed(2).toString() + "%"}</p>
                  ) : (
                    <p className='text-red-500'>{item.one_day.toFixed(2).toString() + "%"}</p>
                  )
                }

                {
                  item.one_week >= 0 ? (
                    <p className='text-green-500'>{"+" + item.one_week.toFixed(2).toString() + "%"}</p>
                  ) : (
                    <p className='text-red-500'>{item.one_week.toFixed(2).toString() + "%"}</p>
                  )
                }
                <p>$ {getStringFromNumber(item.volume)}</p>
                <p>$ {getStringFromNumber(item.marketcap)}</p>
                <p>$ {item.all_time_hight.toFixed(7).toString()}</p>
              </div>
            ))
          }
          <div className='w-36 xs:w-96 sm:hidden'>
            <TokenDetailSlick tokenDetails={[{ ...chihuahuaToken }, { ...dogeToken }, { ...shibaToken }]} />
          </div>
        </div>
      </div>
      <img src={chartSubImage} className='w-full -mt-28 -z-0' alt="chartsub"></img>
      <div className='section-three px-16 md:px-20 lg:px-28 xl:px-72 pt-40 relative'>
        <p className='-mt-36 text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-semibold italic py-3'>IN CHART WE TRUST</p>
        <div className="chart-title pt-4 md:pt-10">
          <hr />
          <div className='flex flex-row border-l-2 pl-4 py-8'>
            <p className={tokenType === 'CHIHUAHUA' ? 'text-sm md:text-lg font-semibold border-b-2 mx-4 cursor-pointer' : 'text-sm md:text-lg font-semibold mx-4 cursor-pointer'} onClick={() => { changeToken2Chihuahua() }}>HUAHUA</p>
            <p className={tokenType === 'DOGE' ? 'text-sm md:text-lg font-semibold border-b-2 mx-4 cursor-pointer' : 'text-sm md:text-lg font-semibold mx-4 cursor-pointer'} onClick={() => { changeToken2Doge() }}>DOGE</p>
            <p className={tokenType === 'SHIBA' ? 'text-sm md:text-lg font-semibold border-b-2 mx-4 cursor-pointer' : 'text-sm md:text-lg font-semibold mx-4 cursor-pointer'} onClick={() => { changeToken2Shiba() }}>SHIBA</p>
          </div>
        </div>
        <div>
          <PrimaryChart
            data={mappedData ?? []}
            height={boxWidth >= 1024 ? Math.floor(height * 0.6) : Math.floor(height * 0.5)}
            width={boxWidth}
            margin={
              boxWidth >= 1280 ? {
                top: 16,
                right: 600,
                bottom: 40,
                left: 64,
              } : boxWidth >= 1024 ? {
                top: 16,
                right: 240,
                bottom: 40,
                left: 64,
              } : boxWidth >= 768 ? {
                top: 16,
                right: 200,
                bottom: 40,
                left: 64,
              } : {
                top: 16,
                right: 160,
                bottom: 40,
                left: 64,
              }}
          />
        </div>
        <div className='flex flex-row justify-center pt-6 items-center'>
          <p onClick={() => setTimeRange(1)} className={timeRange === 1 ? 'text-xs md:text-lg font-semibold cursor-pointer mx-2 sm:mx-4 border-b-2' : 'text-xs md:text-lg font-semibold cursor-pointer mx-2 sm:mx-4'}>24 Hours</p>
          <p onClick={() => setTimeRange(7)} className={timeRange === 7 ? 'text-xs md:text-lg font-semibold cursor-pointer mx-2 sm:mx-4 border-b-2' : 'text-xs md:text-lg font-semibold cursor-pointer mx-2 sm:mx-4'}>7 Days</p>
          <p onClick={() => setTimeRange(30)} className={timeRange === 30 ? 'text-xs md:text-lg font-semibold cursor-pointer mx-2 sm:mx-4 border-b-2' : 'text-xs md:text-lg font-semibold cursor-pointer mx-2 sm:mx-4'}>1 Month</p>
          <p onClick={() => setTimeRange(365)} className={timeRange === 365 ? 'text-xs md:text-lg font-semibold cursor-pointer mx-2 sm:mx-4 border-b-2' : 'text-xs md:text-lg font-semibold cursor-pointer mx-2 sm:mx-4'}>1 Year</p>
        </div>
        <img className='absolute hidden md:block bottom-8 left-8 z-0' src={chartSubDotImage} alt="dot"></img>
        <img className='absolute top-1/3 hidden md:block md:-bottom-20 right-0 w-40 sm:w-56 md:w-60 lg:w-80 z-0' src={chartRightImage} alt="right"></img>
      </div>
      <div className='section-four pt-4 md:pt-20 px-4 md:px-16 lg:px-20 xl:px-28 2xl:px-40'>
        <div className='table-wrap flex flex-row'>
          <img className="pt-10 w-1/3 h-1/3 hidden xl:block" src={burgeDogImage} alt="burgeDogImage"></img>
          <div className='flex flex-col pr-0 pl-0 xl:pl-32 w-full'>
            <div className='flex flex-row items-end px-4'>
              <img className="-mb-16 w-1/3 xl:hidden" src={burgeDogImage} alt="burgeDogImage"></img>
              <img className=' hidden lg:block' src={featureImage} alt="featureImage"></img>
              <div className='w-full'>
                <p className='text-2xl sm:text-4xl xl:text-5xl 2xl:text-6xl text-right italic font-semibold'>DOG’S FEATURES</p>
              </div>
            </div>
            <div className='pt-20 token-special'>
              <div className="feature flex md:hidden flex-row items-end place-content-between">
                <div className='text-sm md:text-lg flex flex-col items-start'>
                  <div className='flex flex-row items-center' onClick={() => handleInfoClick('Type')}>
                    <p className='cursor-pointer'>Type</p>
                    {
                      info === "Type" ? (
                        <img src={infoEnable} width={14} height={14}></img>
                      ) : (
                        <img src={infoDisable} width={14} height={14}></img>
                      )
                    }
                  </div>
                  <div className='flex flex-row items-center' onClick={() => handleInfoClick('Governance')}>
                    <p className='cursor-pointer'>Governance</p>
                    {
                      info === "Governance" ? (
                        <img src={infoEnable} width={14} height={14}></img>
                      ) : (
                        <img src={infoDisable} width={14} height={14}></img>
                      )
                    }
                  </div>
                  <div className='flex flex-row items-center' onClick={() => handleInfoClick('Community Pool')}>
                    <p className='cursor-pointer'>Community Pool</p>
                    {
                      info === "Community Pool" ? (
                        <img src={infoEnable} width={14} height={14}></img>
                      ) : (
                        <img src={infoDisable} width={14} height={14}></img>
                      )
                    }
                  </div>
                  <div className='flex flex-row items-center' onClick={() => handleInfoClick('Apr')}>
                    <p className='cursor-pointer'>APR</p>
                    {
                      info === "Apr" ? (
                        <img src={infoEnable} width={14} height={14}></img>
                      ) : (
                        <img src={infoDisable} width={14} height={14}></img>
                      )
                    }
                  </div>
                  <div className='flex flex-row items-center' onClick={() => handleInfoClick('Airdrop')}>
                    <p className='cursor-pointer'>Airdrop</p>
                    {
                      info === "Airdrop" ? (
                        <img src={infoEnable} width={14} height={14}></img>
                      ) : (
                        <img src={infoDisable} width={14} height={14}></img>
                      )
                    }
                  </div>
                </div>
                <div className='w-36 sm:w-96 md:hidden text-sm md:text-lg'>
                  <TokenFeatureSlick tokenFeatures={tokenFeatures} />
                </div>
              </div>
              <div className='hidden md:block'>
                <table className='w-full'>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Chihuahua</th>
                      <th>Dogecoin</th>
                      <th>Shiba Inu</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={info !== 'Type' ? 'content border-b-2 border-gray-500 cursor-pointer' : 'content cursor-pointer'} onClick={() => handleInfoClick('Type')}>
                      <td>
                        <div className="flex flex-row items-center">
                          <p className='pr-2'>Type</p>
                          {
                            info === 'Type' ? (
                              <img src={infoEnable} width={14} height={14}></img>
                            ) : (
                              <img src={infoDisable} width={14} height={14}></img>
                            )
                          }
                        </div>
                      </td>
                      <td>
                        <p>BFT PoS</p>
                      </td>
                      <td>
                        <p>PoW</p>
                      </td>
                      <td>
                        <p>Token</p>
                      </td>
                    </tr>
                    {
                      info === 'Type' && (
                        <tr className='info border-b-2 border-gray-500'>
                          <td className='text-left' colSpan={4}>
                            <p className='text-sm'>
                              Chihuahua is a blockchain built using the Cosmos SDK and Tendermint consensus (BFT-based Proof-of-Stake). Shiba Inu is a token on Ethereum chain (PoW) and Dogecoin runs on the same proof-of-work (PoW) mechanism as Bitcoin. 
                              <br/><br/>
                              The current estimated annual energy consumption (measured in TWh) of running the Ethereum 2.0 network for a year is ~0.03 TWh (<a href='https://blog.ethereum.org/2021/05/18/country-power-no-more/' target="_blank">link</a>), while Dogecoin consumes around ~6.5 TWh per year (<a href='https://digiconomist.net/dogecoin-energy-consumption/' target="_blank">link</a>). By comparison, the estimated yearly consumption of a Cosmos-based blockchain like Chihuahua is just <b>0.00046647 TWh</b> (<a href='https://blog.cosmos.network/why-blockchains-need-cosmos-proof-of-stake-for-a-sustainable-environment-878b3edd2e85' target="_blank">link</a>).
                              <br/>
                              <br/>
                              BFT-based Proof-of-Stake (BFT-PoS), the consensus method that Chihuahua is built on, is a different blockchain consensus model that is infinitely more energy-efficient than Proof-of-Work. Proof-of-Stake uses validators rather than miners to validate transactions and verify the accuracy of new blocks to be added to the existing chain.
                            </p>
                          </td>
                        </tr>
                      )
                    }

                    <tr className={info !== 'Governance' ? 'content border-b-2 border-gray-500 cursor-pointer' : 'content cursor-pointer'} onClick={() => handleInfoClick('Governance')}>
                      <td>
                        <div className="flex flex-row items-center">
                          <p className='pr-2'>Governance</p>
                          {
                            info === 'Governance' ? (
                              <img src={infoEnable} width={14} height={14}></img>
                            ) : (
                              <img src={infoDisable} width={14} height={14}></img>
                            )
                          }
                        </div>
                      </td>
                      <td align='center'>
                        <img src={checkTrue} width={30} height={30}></img>
                      </td>
                      <td align='center'>
                        <img src={checkFalse} width={30} height={30}></img>
                      </td>
                      <td align='center'>
                        <img src={checkFalse} width={30} height={30}></img>
                      </td>
                    </tr>
                    {
                      info === 'Governance' && (
                        <tr className='info border-b-2 border-gray-500'>
                          <td className='text-left' colSpan={4}>
                            <p className='text-sm'>Blockchains are supposed to be governed by their users. Users that are actively staking $HUAHUA in the Chihuahua chain (delegators) have additional rights, such as the right to vote on governance proposals. The delegators and the validators in the Chihuahua chain can vote on proposals allowing them to change the blockchain parameters, coordinate the upgrades, and vote on the different governance policies allowing them to shape the blockchain as they really want it</p>
                          </td>
                        </tr>
                      )
                    }

                    <tr className={info !== 'Community Pool' ? 'content border-b-2 border-gray-500 cursor-pointer' : 'content cursor-pointer'} onClick={() => handleInfoClick('Community Pool')}>
                      <td>
                        <div className="flex flex-row items-center">
                          <p className='pr-2'>Community Pool</p>
                          {
                            info === 'Community Pool' ? (
                              <img src={infoEnable} width={14} height={14}></img>
                            ) : (
                              <img src={infoDisable} width={14} height={14}></img>
                            )
                          }
                        </div>
                      </td>
                      <td>
                        <p> ~10B HUAHUA</p>
                      </td>
                      <td align='center'>
                        <img src={checkFalse} width={30} height={30}></img>
                      </td>
                      <td align='center'>
                        <img src={checkFalse} width={30} height={30}></img>
                      </td>
                    </tr>
                    {
                      info === 'Community Pool' && (
                        <tr className='info border-b-2 border-gray-500'>
                          <td className='text-left' colSpan={4}>
                            <p className='text-sm'>A Community Pool is a fund that is managed by the governance. A percentage of all staking rewards generated (via block rewards & transaction fees) on the Chihuahua chain is automatically transferred to and accrue within the Community Pool. Funds from the Chihuahua Community Pool may be spent via a successful governance proposal. The Chihuahua Community Pool has currently more than 10 Billion $HUAHUA (~45 Million dollars).</p>
                          </td>
                        </tr>
                      )
                    }

                    <tr className={info !== 'Apr' ? 'content border-b-2 border-gray-500 cursor-pointer' : 'content cursor-pointer'} onClick={() => handleInfoClick('Apr')}>
                      <td>
                        <div className="flex flex-row items-center">
                          <p className='pr-2'>APR</p>
                          {
                            info === 'Apr' ? (
                              <img src={infoEnable} width={14} height={14}></img>
                            ) : (
                              <img src={infoDisable} width={14} height={14}></img>
                            )
                          }
                        </div>
                      </td>
                      <td>
                        <p>~400%</p>
                      </td>
                      <td align='center'>
                        <img src={checkFalse} width={30} height={30}></img>
                      </td>
                      <td align='center'>
                        <img src={checkFalse} width={30} height={30}></img>
                      </td>
                    </tr>
                    {
                      info === 'Apr' && (
                        <tr className='info border-b-2 border-gray-500'>
                          <td className='text-left' colSpan={4}>
                            <p className='text-sm'>APR is an abbreviation for Annual Percentage Rate, which means yearly funds that you get as investment interest. The essence of APR is a simple interest rate, so your profit depends directly on the original investment. For example, if you staked 1,000 $HUAHUA with a 200% yearly interest rate, you will get 3,000 $HUAHUA by the end of the first year.</p>
                          </td>
                        </tr>
                      )
                    }

                    <tr className={info !== 'Airdrop' ? 'content border-b-2 border-gray-500 cursor-pointer' : 'content cursor-pointer'} onClick={() => handleInfoClick('Airdrop')}>
                      <td>
                        <div className="flex flex-row items-center">
                          <p className='pr-2'>Airdrop</p>
                          {
                            info === 'Airdrop' ? (
                              <img src={infoEnable} width={14} height={14}></img>
                            ) : (
                              <img src={infoDisable} width={14} height={14}></img>
                            )
                          }
                        </div>
                      </td>
                      <td align='center'>
                        <img src={checkTrue} width={30} height={30}></img>
                      </td>
                      <td align='center'>
                        <img src={checkFalse} width={30} height={30}></img>
                      </td>
                      <td align='center'>
                        <img src={checkFalse} width={30} height={30}></img>
                      </td>
                    </tr>
                    {
                      info === 'Airdrop' && (
                        <tr className='info border-b-2 border-gray-500'>
                          <td className='text-left' colSpan={4}>
                            <p className='text-sm'>An Airdrop refers to a method of distributing cryptocurrency to the public, via the fact that they own certain other tokens or wallets on a particular blockchain. HUAHUA, the Chihuahua coin, has been distributed to more than 150.000 wallets to the Cosmos ecosystem’s delegators (Cosmos Hub for example).</p>
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className='flex justify-center py-8'>
              <div className='button rounded-full mt-4 px-10 py-2'>
                <a href='https://app.osmosis.zone/?from=OSMO&to=HUAHUA' target='_blank'>Buy</a>
              </div>
            </div>
          </div>
        </div>
        <div className='block md:hidden text-left pt-10'>
          {
            info.length > 0 && info === 'Type' && (
              <div>
                <p className='text-sm md:text-xl'>{info} :</p>
                <p className='text-sm md:text-xl'>
                  Chihuahua is a blockchain built using the Cosmos SDK and Tendermint consensus (BFT-based Proof-of-Stake). Shiba Inu is a token on Ethereum chain (PoW) and Dogecoin runs on the same proof-of-work (PoW) mechanism as Bitcoin. 
                  <br/><br/>
                  The current estimated annual energy consumption (measured in TWh) of running the Ethereum 2.0 network for a year is ~0.03 TWh (<a href='https://blog.ethereum.org/2021/05/18/country-power-no-more/' target="_blank">link</a>), while Dogecoin consumes around ~6.5 TWh per year (<a href='https://digiconomist.net/dogecoin-energy-consumption/' target="_blank">link</a>). By comparison, the estimated yearly consumption of a Cosmos-based blockchain like Chihuahua is just <b>0.00046647 TWh</b> (<a href='https://blog.cosmos.network/why-blockchains-need-cosmos-proof-of-stake-for-a-sustainable-environment-878b3edd2e85' target="_blank">link</a>).
                  <br/>
                  <br/>
                  BFT-based Proof-of-Stake (BFT-PoS), the consensus method that Chihuahua is built on, is a different blockchain consensus model that is infinitely more energy-efficient than Proof-of-Work. Proof-of-Stake uses validators rather than miners to validate transactions and verify the accuracy of new blocks to be added to the existing chain.
                </p>
              </div>
            )
          }
          {
            info.length > 0 && info === 'Governance' && (
              <div>
                <p className='text-sm md:text-xl'>{info} :</p>
                <p className='text-sm md:text-xl'>
                  Blockchains are supposed to be governed by their users. Users that are actively staking $HUAHUA in the Chihuahua chain (delegators) have additional rights, such as the right to vote on governance proposals. The delegators and the validators in the Chihuahua chain can vote on proposals allowing them to change the blockchain parameters, coordinate the upgrades, and vote on the different governance policies allowing them to shape the blockchain as they really want it
                </p>
              </div>
            )
          }
          {
            info.length > 0 && info === 'Community Pool' && (
              <div>
                <p className='text-sm md:text-xl'>{info} :</p>
                <p className='text-sm md:text-xl'>
                  A Community Pool is a fund that is managed by the governance. A percentage of all staking rewards generated (via block rewards & transaction fees) on the Chihuahua chain is automatically transferred to and accrue within the Community Pool. Funds from the Chihuahua Community Pool may be spent via a successful governance proposal. The Chihuahua Community Pool has currently more than 10 Billion $HUAHUA (~45 Million dollars).
                </p>
              </div>
            )
          }
          {
            info.length > 0 && info === 'Apr' && (
              <div>
                <p className='text-sm md:text-xl'>{info} :</p>
                <p className='text-sm md:text-xl'>
                  APR is an abbreviation for Annual Percentage Rate, which means yearly funds that you get as investment interest. The essence of APR is a simple interest rate, so your profit depends directly on the original investment. For example, if you staked 1,000 $HUAHUA with a 200% yearly interest rate, you will get 3,000 $HUAHUA by the end of the first year.
                </p>
              </div>
            )
          }
          {
            info.length > 0 && info === 'Airdrop' && (
              <div>
                <p className='text-sm md:text-xl'>{info} :</p>
                <p className='text-sm md:text-xl'>
                  An Airdrop refers to a method of distributing cryptocurrency to the public, via the fact that they own certain other tokens or wallets on a particular blockchain. HUAHUA, the Chihuahua coin, has been distributed to more than 150.000 wallets to the Cosmos ecosystem’s delegators (Cosmos Hub for example).
                </p>
              </div>
            )
          }
        </div>
      </div>
      <div className='pt-4 w-full block sm:hidden'>
        <img src={footerImage} className='w-full'></img>
      </div>
    </div>
  );
}

export default App;
